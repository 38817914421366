import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Navbar from '../../navbar/Navbar'
import { withRouter } from 'react-router-dom'
import Footer from '../../footer/Footer'
const Layout = ({ children, location, ...rest }) => {
	return (
		<>
			<Box sx={{ width: '100vw', overflow: 'hidden' }}>
				<Navbar />
			</Box>
			<Box sx={{ minHeight: '80vh' }}>{children}</Box>
			<Box
				sx={{
					left: '0',
					zIndex: 100,
					bottom: '0',
					height: '40px',
					position: 'fixed',
					paddingTop: '3px',
					overflowX: 'hidden',
					overflowY: 'hidden',
					paddingBottom: '3px',
					background: '#1976d2',
					width: { lg: '100%', md: '100%', sm: '100%', xl: '100%', xs: '100%' },
				}}
			>
				<Footer />
			</Box>
		</>
	)
}

Layout.propType = {
	children: PropTypes.any,
	location: PropTypes.object,
}

export default withRouter(Layout)
