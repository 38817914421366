import Login from '../pages/auth/Login'
import User from '../pages/profile/User'
import Plans from '../pages/plans/Plans'
import Signup from '../pages/auth/Signup'
import VerifyEmail from '../pages/auth/VerifyEmail'
import ManagePlans from '../pages/plans/ManagePlans'
import Unsubscribe from '../pages/plans/Unsubscribe'
import Activate2FA from '../pages/utils/Activate2FA'
import Dashboard from '../pages/dashboard/Dashboard'
import Decrypt from '../components/decryption/Decrypt'
import TwoFactorAuth from '../pages/auth/TwoFactorAuth'
import ChangePassword from '../pages/utils/ChangePassword'
import ForgetPassword from '../pages/utils/ForgetPassword'
import AdminDashboard from '../pages/dashboard/AdminDashboard'
import NoAuthDashboard from '../pages/dashboard/NoAuthDashboard'
import NoAuthDecrypt from '../components/decryption/NoAuthDecrypt'
import VerifyEmailRedirect from '../pages/utils/VerifyEmailRedirect'

const publicRoutes = [
	{ path: '/', exact: true, component: Login },
	{ path: '/signup', component: Signup },
	{ path: '/verification/pending', component: VerifyEmailRedirect },
	{ path: '/auth-2fa', component: TwoFactorAuth },
	{ path: '/forget-password', component: ForgetPassword },
	{ path: '/verifyEmail/:id', component: VerifyEmail },
	{ path: '/guest-dashboard', component: NoAuthDashboard },
	{ path: '/guest-encrypt-file', component: NoAuthDashboard },
	{ path: '/guest-encrypt-text', component: NoAuthDashboard },
	{ path: '/guest/:id', component: NoAuthDecrypt },
]

const protectedRoutes = [
	{ path: '/plans/unsubscribe', component: Unsubscribe },
	{ path: '/change-password', component: ChangePassword },
	{ path: '/dashboard', component: Dashboard },
	{ path: '/admin-dashboard', component: AdminDashboard },
	{ path: '/manage-plans', component: ManagePlans },
	{ path: '/activate-2fa', component: Activate2FA },
	{ path: '/encrypt-file', component: Dashboard },
	{ path: '/encrypt-text', component: Dashboard },
	{ path: '/generate-url', component: Dashboard },
	{ path: '/profile/:id', component: User },
]

const customAuthRoutes = [
	{ path: '/plans', component: Plans },
	{ path: '/:id', component: Decrypt },
]

export { publicRoutes, protectedRoutes, customAuthRoutes }
