import { Alert, AlertTitle, IconButton, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { LoadingButton } from "@mui/lab"
import { Redirect } from "react-router-dom"
import { useRouteMatch } from "react-router"
import { useTranslation } from "react-i18next"
import React, { useEffect, useState } from "react"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import EncryptionService from "../../../api-services/enc-service"

const EncryptFile = () => {
  const { url } = useRouteMatch()
  const { t, i18n } = useTranslation()

  const [file, setFile] = useState("")
  const [loading, setLoading] = useState(false)
  const [responseURL, setResponseURL] = useState("")
  const [URLRemoved, setURLRemoved] = useState(false)

  const handleEncryptionClick = async () => {
    setLoading(true)

    const encryptionFormData = new FormData()
    encryptionFormData.append("text", "")
    encryptionFormData.append("file", file === "" ? "" : file)

    if (url.includes("guest")) {
      encryptionFormData.append("id", localStorage.getItem("id"))

      const response = await EncryptionService.encryptDataGuest(
        encryptionFormData
      )

      if (!response.error) {
        setLoading(false)
        setFile("")
        setResponseURL(response.url)
      } else {
        setLoading(false)
        if (response.msg === "Url has been removed") {
          setURLRemoved(true)
        }
      }
    } else {
      encryptionFormData.append("user_id", localStorage.getItem("user_id"))

      const response = await EncryptionService.encryptData(encryptionFormData)

      if (!response.error) {
        setLoading(false)
        setFile("")
        setResponseURL(response.url)
      } else {
        setLoading(false)
        if (response.msg === "Url has been removed") {
          setURLRemoved(true)
        }
      }
    }
  }

  useEffect(() => {
    if (file !== "") handleEncryptionClick()
  }, [file])
  console.log(file)
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "5%",
        alignItems: "center",
        borderRadius: "15px",
        height: { lg: "70vh", md: "70vh", sm: "70vh", xs: "70vh" },
        flexDirection: { lg: "row", md: "column", sm: "column", xs: "column" },
      }}
    >
      <Box
        sx={{
          background: "#b3e6ff",
          borderTopLeftRadius: "12px",
          borderBottomLeftRadius: { lg: "12px" },
          width: { lg: "60%", md: "100%", sm: "100%", xs: "100%" },
          height: { lg: "100%", md: "40%", sm: "40%", xs: "50%" },
          borderTopRightRadius: { lg: 0, md: "12px", sm: "12px", xs: "12px" },
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
          }}
        >
          <Typography
            display='block'
            variant='body1'
            sx={{
              lineHeight: "1.4em",
              fontSize: { lg: "20px", md: "20px", sm: "15px", xs: "15px" },
              mx:"1rem"
            }}
          >
            {responseURL !== "" && url.includes("guest")
              ? t("fileEncrypted")
              : responseURL !== ""
              ? URLRemoved
                ? t("dataAccessed")
                : t("copyLinkAndSend")
              : t("selectFileToEncrypt")}
          </Typography>
          {responseURL === "" ? (
            <></>
          ) : url === "/guest-encrypt-file" ? (
            <></>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                display='block'
                variant='subtitle2'
                sx={{
                  fontSize: { lg: "20px", md: "20px", sm: "15px", xs: "15px" },
                  textOverflow: {
                    sm: "ellipsis",
                    md: "ellipsis",
                    xs: "ellipsis",
                  },
                  whiteSpace: { md: "nowrap", sm: "nowrap", xs: "nowrap" },
                  overflow: { md: "hidden", sm: "hidden", xs: "hidden" },
                  width: {
                    lg: "100%",
                    sm: "400px",
                    md: "500px",
                    xs: "150px",
                    xl: "100%",
                  },
                }}
              >
                <p
                  id='responseLink'
                  style={{ margin: 0 }}
                  data-link={responseURL}
                >
                  {responseURL}
                </p>
              </Typography>
              <IconButton
                edge='end'
                id='copyButton'
                data-clipboard-target='#responseLink'
              >
                {<ContentCopyIcon />}
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          background: "#e6e6e6",
          flexDirection: "column",
          borderBottomRightRadius: "12px",
          borderTopRightRadius: { lg: "12px" },
          height: { lg: "100%", md: "70%", sm: "70%", xs: "80%" },
          width: { lg: "40%", md: "100%", sm: "100%", xs: "100%" },
          borderBottomLeftRadius: {
            lg: "12px",
            md: "12px",
            sm: "12px",
            xs: "12px",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {responseURL !== "" && url.includes("guest") ? (
              <Alert
                sx={{
                  fontSize: { xs: "13px" },
                  borderBottomRightRadius: "12px",
                  borderBottomLeftRadius: {
                    lg: 0,
                    md: "12px",
                    sm: "12px",
                    xs: "12px",
                  },
                }}
                severity='warning'
              >
                <AlertTitle>{t("warning")}</AlertTitle>
                {t("linkSent")} {t("dataAccesibleFor")}{" "}
                <strong>{t("1Time")}</strong>
              </Alert>
            ) : URLRemoved ? (
              <Box
                sx={{
                  width: { lg: "100%" },
                }}
              >
                <Alert
                  sx={{
                    fontSize: { xs: "13px" },
                    borderBottomRightRadius: "12px",
                    borderBottomLeftRadius: {
                      lg: 0,
                      md: "12px",
                      sm: "12px",
                      xs: "12px",
                    },
                  }}
                  severity='error'
                >
                  <AlertTitle>{t("error")}</AlertTitle>
                  {t("dataAccessed")}
                </Alert>
              </Box>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                  mt: { lg: 2, md: 2, sm: 2, xs: 2 },
                }}
              >
                <LoadingButton
                  variant='contained'
                  component='label'
                  loading={loading}
                  loadingPosition='end'
                  endIcon={<FileUploadIcon />}
                >
                  {loading ? t("uploading") : t("selectFile")}
                  <input
                    hidden
                    type='file'
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </LoadingButton>
              </Box>
            )}
            {responseURL !== "" ? (
              <Typography
                sx={{ fontSize: "0.84rem", mt: "1rem",  }}
                display='block'
                variant='subtitle2'
              >
                {t("refreshMassage")}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        </Box>
        {responseURL !== "" && (url.includes("guest") || URLRemoved) ? (
          <></>
        ) : (
          <Box
            sx={{
              width: { lg: "100%" },
            }}
          >
            <Alert
              sx={{
                fontSize: { xs: "13px" },
                borderBottomRightRadius: "12px",
                borderBottomLeftRadius: {
                  lg: 0,
                  md: "12px",
                  sm: "12px",
                  xs: "12px",
                },
              }}
              severity='warning'
            >
              <AlertTitle>{t("warning")}</AlertTitle>
              {t("dataAvailableAfer1TimeUse")}
            </Alert>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default EncryptFile
