// import toast from 'react-hot-toast'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import AuthService from '../../api-services/auth-service'
import { Alert, Snackbar } from '@mui/material'
import { useTranslation } from 'react-i18next'

const VerifyEmail = () => {
	const { id } = useParams()
	const history = useHistory()
	const { t, i18n } = useTranslation()

	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')
	const [alertOpen, setAlertOpen] = useState(false)
	const [responseReceived, setResponseReceived] = useState(false)

	useEffect(() => {
		const verifyEmail = async () => {
			const response = await AuthService.verifyEmail(id)

			if (!response.error) {
				setResponseReceived(true)
				setSuccess(t('verificationSuccessfulRedirectToLogin'))
				setAlertOpen(true)

				setTimeout(() => {
					history.replace('/verification/pending')
				}, 3000)
			} else {
				setResponseReceived(true)
				setError(response.msg)
			}
		}

		verifyEmail()
	}, [])

	return (
		<>
			<div>
				<h4 style={{ textAlign: 'center' }}>
					{responseReceived
						? error !== ''
							? error
							: t('verificationSuccessRedirecting')
						: t('waitForVerification')}
				</h4>
			</div>
			<Snackbar
				open={alertOpen}
				autoHideDuration={6000}
				onClose={() => setAlertOpen(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert
					sx={{ width: '100%' }}
					onClose={() => setAlertOpen(false)}
					severity={error === '' ? 'success' : 'error'}
				>
					{error === '' ? success : error}
				</Alert>
			</Snackbar>
		</>
	)
}

export default VerifyEmail
